<template>
    <div>
        <Navbar page="Relatório de cookies" link="/entregaveis" nameLink="Entregáveis" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="text-3xl mb-5">Relatório de cookies</h1>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-4">
                    <label for="tipo_relatorio" class="block text-sm font-medium"> 
                        Site
                    </label>
                    <select v-model="site" for="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option default :value="null">Todos</option>
                        <option v-for="site in sites" :key="site._id" :value="site._id">{{ site.url }}</option>
                    </select>
                </div>
                
               
                <div class="col-span-12 md:col-span-2">
                    <label class="block text-sm font-medium"> 
                        Download
                    </label>

                    <a :href="`${this.url_api}/v1/entregaveis/cookiesdocx/${$store.state.empresa._id}/${site}?token=${this.$store.state.token}`" target="_blank" class="block mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            sites: [],
            site: null,
        }
    },
    async beforeMount() {
        const cookiesReq = await this.$http.post(`/v1/cookies/list`, { all: true });
        this.sites = cookiesReq.data.data;
    }
}
</script>